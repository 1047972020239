export enum TableColumnFilterType {
  INPUT = "INPUT",
  SELECTOR = "SELECTOR",
}

export class TableColumn {
  constructor(
    public key: string,
    public nameKey?: string,
    public fixed = false,
    public hasFilter = false,
    public filterType?: TableColumnFilterType,
  ) {}
}
